<template>
    <div>
        <el-row >
            <el-col>
                <el-button type="primary" @click="search" >{{$t('msg28')}}</el-button>
                <el-button type="success" icon="el-icon-search" @click="getTodaySet();getLog(1);" style="margin-left: 15px;">{{$t('msg152')}}</el-button>
                <el-button type="success" icon="el-icon-search" @click="getYdaySet();getLog(1);" style="margin-left: 15px;">{{$t('msg172')}}</el-button>
                <el-button type="success" icon="el-icon-search" @click="getLWKSet();getLog(1);" style="margin-left: 15px;">{{$t('msg153')}}</el-button>
                <el-button type="success" icon="el-icon-search" @click="getTMonSet();getLog(1);" style="margin-left: 15px;">{{$t('msg173')}}</el-button>                
                <el-button type="success" icon="el-icon-search" @click="getLMonSet();getLog(1);" style="margin-left: 15px;">{{$t('msg154')}}</el-button>                
            </el-col>
            <div class="mainSearch" >
                <table cellspacing="0" cellpadding="0" border="0" class="mainTable">
                    <tr>
                        <td class="td-head td-min">{{$t('msg56')}}</td>
                        <td class="td-head ">{{$t('msg9')}}</td>
                        <td class="td-head ">{{$t('msg41')}}</td>
                        <td class="td-head " >{{$t('msg57')}}</td>
                        <td class="td-head " >出款金額({{cashfee}}%)</td>
                        <td class="td-head " style="width: 250px;">{{$t('msg93')}}</td>
                        <td class="td-head " >{{$t('msg60')}}</td>
                        <td class="td-head td-date">{{$t('msg25')}}</td>
                        <td class="td-head td-date">{{$t('msg14')}}</td>
                        <td class="td-head">{{$t('msg15')}}</td>
                    </tr>
                    <tr v-for="(item, index) in results" :key="index" :class="{'tr-color':index%2==0}">
                        <td class="td-content">{{ item.ID }}</td>
                        <td class="td-content">
                            <el-tag v-if="item.Status==0">{{ getCashStatus(item) }}</el-tag>
                            <el-tag type="warning" v-if="item.Status==1">{{ getCashStatus(item) }}</el-tag>
                            <el-tag type="info" v-if="item.Status==2">{{ getCashStatus(item) }}</el-tag>
                        </td>
                        <td class="td-content">{{ item.Username }}</td>
                        <td class="td-content">{{ item.Amount | numFilter }}</td>
                        <td class="td-content">{{ item.Amount - (item.Amount*cashfee/100) | numFilter }}</td>
                                                
                        <td class="td-content" style="word-wrap:break-word">
                                <el-card shadow="hover">
                                    <div>{{ item.Account }}</div>
                                </el-card>
                        </td>
                        <td class="td-content">{{ item.Info }}</td>
                        <td class="td-content">{{ getMomentFullDate(item.CreatedAt) }}</td>
                        <td class="td-content">{{ getMomentFullDate(item.UpdatedAt) }}</td>
                        <td class="td-content">
                            <!-- <el-button type="primary" @click="update(item)" v-if="item.Status==0 && user.GroupPermission.n4.Modify" size="mini">{{$t('msg16')}}</el-button> -->
                                <el-col :span="12">
                                    <el-button style="width: 80%;" type="primary" size="mini" :loading="loading" @click="update(item,1)" v-if="item.Status==0 && user.GroupPermission.n4.Modify">{{$t('msg64')}}</el-button>
                                </el-col>
                                <el-col :span="12">
                                    <el-button style="width: 80%;" type="primary" size="mini" :loading="loading" @click="update(item,2)" v-if="item.Status==0 && user.GroupPermission.n4.Modify">{{$t('msg65')}}</el-button>
                                </el-col>                             
                        </td>
                    </tr>
                </table>
                <div class="no-record" v-if="!results">
                    <img class="no-record-img" src="@/assets/images/norecord.png">
                    <div class="no-record-txt">{{$t('msg17')}}</div>
                </div>
                <el-row>
                    <el-col :span="24">
                        <i class="fas fa-clock time-icon"></i> {{getMomentYDate(filterData.Date[0])}} ~ {{getMomentYDate(filterData.Date[1])}} | {{$t('msg88')}} : {{sumTotal | numFilter}}
                    </el-col>
                </el-row >
                <div class="mainPage">
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      :total="page.total"
                      :page-size="page.pageSize"
                      :current-page="page.page"
                      @current-change="getLog"
                      >
                    </el-pagination>
                </div>
            </div>
        </el-row>
        <el-dialog :visible.sync="dialog.Visible" :destroy-on-close="true" :close-on-click-modal="false" width="480px" >
            <el-row>
                <el-col >
                    <el-row>
                        <el-col>
                          <el-form :model="from" ref="ruleForm"  status-icon>
                              <el-col :span="24">
                                <el-form-item :label="$t('msg67')" class="form-item">
                                    <el-col class="form-info">
                                        <el-card shadow="hover">
                                            <div>{{$t('Bank.name')}} : {{ from.BankName }}</div>
                                            <div>{{$t('Bank.accountName')}} : {{ from.Accountname }}</div>
                                            <div>{{$t('Bank.account')}} : {{ from.Account }}</div>
                                        </el-card>
                                    </el-col>
                                </el-form-item>
                              </el-col>
                              <el-col :span="24">
                                <el-form-item :label="$t('msg41')" class="form-item">
                                    <el-col class="form-info">
                                        {{$t('msg57')}} : {{from.Amount}}
                                    </el-col>
                                </el-form-item>
                              </el-col>
                              <el-col :span="24">
                                <el-form-item :label="$t('msg60')" class="form-item">
                                    <el-col class="form-info">
                                        <el-input v-model="from.Info"  type="textarea" :rows="3" :placeholder="$t('msg60')" ></el-input>
                                    </el-col>
                                </el-form-item>
                              </el-col>
                          </el-form>
                        </el-col>
                        <el-col>
                            <div class="dialog-footer">
                                <el-col :span="12">
                                    <el-button style="width: 80%;" type="primary" :loading="loading" @click="updated(1)" v-if="user.GroupPermission.n4.Modify">{{$t('msg68')}}</el-button>
                                </el-col>
                                <el-col :span="12">
                                    <el-button style="width: 80%;" type="primary" :loading="loading" @click="updated(2)" v-if="user.GroupPermission.n4.Modify">{{$t('msg69')}}</el-button>
                                </el-col>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-dialog>
        <el-dialog :visible.sync="filterData.Visible" :destroy-on-close="true" :close-on-click-modal="false" width="600px">
            <el-row>
                <el-col >
                    <el-form :model="filterData"  status-icon>
                    <el-col :span="24">
                        <el-form-item :label="$t('msg66')" prop="Date" class="form-item">
                            <el-date-picker
                                v-model="filterData.Date"
                                type="daterange"
                                align="right"
                                unlink-panels
                                range-separator="~"
                                :start-placeholder="$t('msg29')"
                                :end-placeholder="$t('msg29')"
                                >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('msg41')" class="form-item">
                          <el-input v-model="filterData.Username" :placeholder="$t('msg41')" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('msg9')" class="form-item">
                            <el-col>
                                <el-select v-model="filterData.CashStatus" :placeholder="$t('msg20')">
                                    <el-option
                                        v-for="item in CashStatus"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-form-item>
                    </el-col>
                  </el-form>
                </el-col>
                <el-col class="dialog-footer">
                    <el-button type="success" icon="el-icon-search" @click="searched">{{$t('msg28')}}</el-button>
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6" >
import moment from 'moment'
import { apiCashList,apiCashSet } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
export default {
    mixins: [globalMixin],
    data() {
        return {
            ucoinRate:0,
            cashfee:0,
            act:"set",
            loading:false,
            dialog:{
                Visible:false,
            },
            from:{
                ID:0,
                BankName:"",
                Accountname:0,
                Account:"",
                Amount:0,
                Info:"",
            },
            page:{
                page:0,
                pageSize:0,
                total:0,
                totalPage:0,
            },
            filterData:{
                Visible:false,
                Date:'',
            },
            results:[],
            sumTotal:0,
        }
    },
    methods: {
        search()
        {
            this.filterData.Visible=true
        },
        searched()
        {
            this.filterData.Visible=false
            this.getLog(1);
        },
        update(item,status)
        {
            this.act="set"
            this.from.ID=item.ID
            this.from.BankName=item.BankName
            this.from.Accountname=item.Accountname
            this.from.Account=item.Account
            this.from.Amount=item.Amount
            this.from.Info=item.Info
            //this.dialog.Visible=true
            if(status==2){
                this.$prompt(this.$t('msg150'), 'Info', {
                          confirmButtonText: this.$t('msg64'),
                          cancelButtonText: this.$t('msg151'),
                        }).then(({ value }) => {
                          this.from.Info=value
                          this.updated(status)
                        }).catch(() => {
                          return    
                        }); 
            }else{
                this.updated(status)
            }
            //this.updated(status)
        },
        updated(status)
        {
            // let chk = false
            // this.$refs["ruleForm"].validate((valid) => {
            //   if (!valid) {
            //     chk = true;
            //   }
            // },chk);
            // if(chk)
            // {
            //     this.message(this.$t('msg33'))
            //     return;
            // }
            this.dialog.Visible=false
            var api = apiCashSet
            let setPage = 1
            switch(this.act)
            {
                case "set":
                    api = apiCashSet
                    setPage = this.page.page
                break;
            }
            api({
                ID: this.from.ID,
                Status: status,
                Info: this.from.Info,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.getLog(setPage)
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        getLog(page)
        {
            let shdate = this.getSEDate(this.filterData.Date)
            apiCashList({
                Page: page,
                FromTime: shdate.FromTime,
                ToTime: shdate.ToTime,
                CashStatus:this.filterData.CashStatus,
                Username:this.filterData.Username,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.results=response.data.data.data;
                        this.page=response.data.data.page;
                        this.sumTotal=response.data.data.sum;
                        this.ucoinRate = response.data.data.ucoinRate;
                        this.cashfee = response.data.data.cashfee;
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
    },
    computed: {
		...mapGetters({
            config:'config',
            user:'user',
        }),
        'CashStatus'()
        {
            return[
                {
                  value: '',
                  label: 'ALL'
                },
                {
                  value: 0,
                  label: this.$t('CashStatus.0')
                }, {
                  value: 1,
                  label: this.$t('CashStatus.1')
                }, {
                  value: 2,
                  label: this.$t('CashStatus.2')
                }
            ]
        },

    },
    components: {
    },
    created() {
        let FromTime = moment(new Date()).add('day',-14).format("YYYY-MM-DD")
        let ToTime = moment(new Date()).add('day',0).format("YYYY-MM-DD")
        this.filterData.Date=[FromTime,ToTime]
        this.getLog(1);
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>

</style>